.portfoliopage {
  /* background-color: var(--pastel-yellow); */
  background-image: url('../Images/17545-03.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.portfoliopage .row {
  height: 100%;
}

.portfolio-home {
  height: 100%;
  background-image: url("../Images/portfolio-main.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.portfolio-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* LIST ITEMS */

.portfoliopage ul {
  list-style: none;
  text-align: left;
}

.portfoliopage ul li {
  margin-bottom: 0.75rem;
  color: var(--grey-dark);
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.portfoliopage ul li.active {
  transform: scale(1.1);
}

.portfoliopage ul li:hover {
  transform: scale(1.1);
}

.portfoliopage ul li span {
  margin-left: 0.5rem;
  text-transform: lowercase;
  color: #aea887;
  font-weight: 300;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.portfoliopage h1::selection,
.portfoliopage ul li span::selection,
.portfoliopage ul li::selection {
  color: white;
  background-color: var(--grey-dark);
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .portfoliopage {
    background-image: none;
    background-color: var(--pastel-yellow);
    overflow: initial;
    overflow-x: hidden;
  }

  .portfoliopage h1 {
    padding: 0 2rem;
    margin: 7rem 0 1rem 0;
  }

  .portfoliopage ul li span {
    display: block;
    margin-left: 0;
  }

  .portfoliopage ul {
    margin-left: 0;
    padding-left: 0; 
    margin-bottom: 2rem;
  }

  .portfoliopage ul li {
    margin-bottom: 0.5rem;
  }

  .portfolio-home {
    height: 25rem;
  }
}
