.skillspage {
  /* background-color: var(--pastel-orange); */
  background-image: url('../Images/17545-04.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skillspage p {
  padding: 0 7rem;
  margin-bottom: 3rem;
}

.skillspage span {
  font-weight: 500;
}

.skillspage ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.skillspage ul li {
  margin-bottom: 0.3rem;
}

.skillspage .designer {
  font-family: 'Oswald', sans-serif;
  display: inline-block;
  background-color: #c3a484;
  padding: 10px 15px;
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
}

.skillspage .webdesigner {
  font-family: 'Oswald', sans-serif;
  display: inline-block;
  background-color: var(--grey-dark);
  color: white !important;
  padding: 10px 15px;
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
}

.skillspage h3 {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 900;
}

.col-lg-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 3rem;
}

.pieChart {
  width: 400px;
  padding: 0 3rem;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.skillspage h1::selection,
.skillspage p::selection,
.skillspage h3::selection,
.skillspage li::selection,
.webdesigner::selection,
.designer::selection,
.skillspage span::selection {
  color: white;
  background-color: var(--grey-dark);
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .skillspage {
    background-image: none;
    background-color: var(--pastel-orange);
    display: block;
    overflow: initial;
    overflow-x: hidden;
    padding-bottom: 2rem;
    align-items: flex-start;
  }

  .skillspage .line {
    margin: 2rem auto;
  }

  .skillspage p {
    padding: 0 2rem;
  }

  .skillspage h1 {
    padding: 0 2rem;
    margin: 7rem 0 1.5rem 0;
  }

  .pieChart {
    width: 300px;
    margin: 1rem 0;
    padding-bottom: 2.5rem;
  }
}

@media only screen and (min-width: 1500px) {
  .skillspage p {
    padding: 0 15rem;
  }
}
