@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;500;700&family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;500;700&family=Roboto:wght@100;300;400;500;700;900&display=swap);
:root {
  --grey-light: #c0c0c0;
  --grey-dark: #262626;
}

* {
  box-sizing: border-box;
  margin: 0;
}

/* NAVBAR STYLING */

/* NAVBAR WEBSITE (VERTICAL) */

.nav-web {
  transform-origin: left top;
  transform: rotate(-90deg) translateX(-100%);
  width: 100vh;
  height: 15vh;
  background-color: white;
  position: absolute;
  z-index: 10;
}

.nav-web ul {
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.nav-web ul li {
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Oswald", sans-serif;
}

.nav-web ul li a:hover {
  color: #262626;
  color: var(--grey-dark);
  text-decoration: none;
}

.nav-web ul li a {
  color: #c0c0c0;
  color: var(--grey-light);
}

.nav-web .logo {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
  display: flex;
  flex: 0 1;
  flex-basis: 50px;
  content: " ";
  transform: rotate(90deg);
}

.nav-web ul li a.active {
  color: #262626;
  color: var(--grey-dark);
}

/* NAVBAR MOBILE (HORIZONTAL) */

.nav-mobile{
  z-index: 100;
  display: none;
}

.navbar-nav li a {
  text-transform: uppercase;
  color: #262626;
  font-family: Oswald;
  font-size: 1.5rem;
}

.navbar-brand a {
  text-transform: uppercase;
  color: #262626;
  font-size: 2.5rem;
  font-weight: 800;
}

.navbar-brand a:hover {
  color: #262626;
}

.navbar{
  display: none !important;
}

.bg-light {
  background-color: white !important;
}

.nav-mobile ul li a.active {
  text-decoration: underline;
}

/* RESPONSIVE SIZES */

@media screen and (max-width: 992px) {
  .nav-web {
    display: none;
    margin-right: 0;
  }

  .navbar{
    display: block !important;
    margin-right: 0;
  }
}

/* PRIMARY COLORS */

:root {
  --grey-light: #c0c0c0;
  --grey-dark: #262626;
  
  /* BACKGROUNDS */
  --pastel-blue: #a5c8e4;
  --pastel-green: #c0eccc;
  --pastel-yellow: #f9f0c1;
  --pastel-orange: #f4cda6;
  --pastel-red: #f6a8a6;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.navbar-div {
  height: 0;
}

.fullpage {
  overflow: hidden;
  margin-left: 15vh;
  height: 100vh;
}

.fullpage h1 {
  font-family: 'Oswald', sans-serif;
  color: #262626;
  color: var(--grey-dark);
  text-transform: uppercase;
  font-size: 4rem;
  margin: 1.5rem 0;
}

.fullpage h2 {
  font-family: 'Oswald', sans-serif;
  color: #262626;
  color: var(--grey-dark);
  text-transform: uppercase;
  font-size: 1.5rem;
}

.fullpage p {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #262626;
  color: var(--grey-dark);
  line-height: 1.5;
}

/* BUTTON STYLING */

.fullpage .btn {
  width: 10rem;
  background-color: white;
  border: none;
  border-radius: 0;
  padding: 0.5rem 0.75rem;
}

.fullpage .btn:hover {
  color: white;
  border: none;
  transition: background-color 0.25s ease-in;
}

.fullpage .btn:focus,
.fullpage .btn:active {
  outline: none;
}

/* LINE STYLING */

.line {
  height: 10px;
  width: 100px;
  border-bottom: 3px solid #262626;
  border-bottom: 3px solid var(--grey-dark);
  margin-bottom: 1.5rem;
}

.line:hover {
  width: 250px;
  transition: 1s ease-in-out;
}

/* MOBILE VERSION */

@media only screen and (max-width: 992px) {
  .fullpage {
    margin-left: 0;
    overflow-y: visible;
    overflow-y: initial;
    height: 100vh;
  }
}

.homepage {
  /* background-color: var(--pastel-blue); */
  background-image: url(/static/media/17545.dcd0e92b.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage h1 {
  font-size: 7rem;
  margin-bottom: 0rem;
}

.homepage h2 {
  margin: 1rem 0;
}

.homepage .graphic {
  font-family: 'Courgette', cursive;
  text-transform: lowercase;
  font-size: 2rem;
}

.homepage .developer {
  font-family: 'Source Code Pro', monospace;
  font-size: 1.75rem;
  text-transform: uppercase;
  letter-spacing: -3px;
}

/* BUTTON HOMEPAGE */

.homepage button {
  color: #84a0b6;
  margin-top: 1.5rem;
}

.homepage button:hover {
  background-color: #84a0b6;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.homepage h1::selection,
.homepage h2::selection,
.homepage button::selection,
.homepage .developer::selection,
.homepage .graphic::selection {
  color: white;
  background-color: var(--grey-dark);
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .homepage{
    background-image: none;
    background-color: var(--pastel-blue);
  }
  
  .homepage h1 {
    padding: 0 2rem;
    font-size: 5.5rem;
    line-height: 1;
  }

  .homepage .graphic,
  .homepage .developer {
    display: block;
    margin: 0.5rem 0;
  }
}

.aboutpage {
  background-image: url(/static/media/17545-02.b3aeacd1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutpage .row {
  min-height: 100vh;
}

.photo {
  background-image: url(/static/media/profile.dc760005.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 35rem;
}

.about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutpage h2 {
  margin: 1rem 0 1.5rem 0;
}

.aboutpage p {
  padding: 0 5rem;
  margin-bottom: 1rem;
}

.about-text span {
  font-weight: 500;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.aboutpage h1::selection,
.aboutpage p::selection,
.aboutpage h2::selection,
.about-text span::selection {
  color: white;
  background-color: var(--grey-dark);
}

/* ICONS STYLING */

.icons {
  display: flex;
  flex-direction: row;
}

.icons .icon-paragraph {
  padding: 0 1rem;
  font-size: 1.3rem;
  position: relative;
  display: inline-block;
  color: #86a58e;
}

.icons .icon-paragraph:hover {
  transform: rotate(10deg);
  transition: transform 0.5s ease-in-out;
}

#iconTip,
#swimTip,
#sportTip,
#foodTip {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .aboutpage {
    background-image: none;
    background-color: var(--pastel-green);
    padding-bottom: 3rem;
    overflow-x: hidden;
  }

  .aboutpage p {
    padding: 0 2rem;
  }

  .aboutpage h1 {
    padding: 0 2rem;
  }

  .photo {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.carousel-item img {
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%; /* Center the image within the element */
}

.center-cropped {
  /* width: 100px; */
  height: 100vh;
  overflow: hidden;
}
.center-cropped img {
  height: 100%;
  min-width: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" fill="%23262626" width="8" height="8" viewBox="0 0 8 8"%3e%3cpath d="M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z"/%3e%3c/svg%3e') !important;
}

.carousel-control-next-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23262626' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.carousel-indicators li{
  background-color: #262626 !important;
}

@media only screen and (max-width: 992px) {
  .center-cropped {
    /* width: 100px; */
    height: 50vh;
    overflow: hidden;
  }
  .center-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
}

.portfoliopage {
  /* background-color: var(--pastel-yellow); */
  background-image: url(/static/media/17545-03.6582e16a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.portfoliopage .row {
  height: 100%;
}

.portfolio-home {
  height: 100%;
  background-image: url(/static/media/portfolio-main.d0b9518a.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.portfolio-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* LIST ITEMS */

.portfoliopage ul {
  list-style: none;
  text-align: left;
}

.portfoliopage ul li {
  margin-bottom: 0.75rem;
  color: var(--grey-dark);
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.portfoliopage ul li.active {
  transform: scale(1.1);
}

.portfoliopage ul li:hover {
  transform: scale(1.1);
}

.portfoliopage ul li span {
  margin-left: 0.5rem;
  text-transform: lowercase;
  color: #aea887;
  font-weight: 300;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.portfoliopage h1::selection,
.portfoliopage ul li span::selection,
.portfoliopage ul li::selection {
  color: white;
  background-color: var(--grey-dark);
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .portfoliopage {
    background-image: none;
    background-color: var(--pastel-yellow);
    overflow: visible;
    overflow: initial;
    overflow-x: hidden;
  }

  .portfoliopage h1 {
    padding: 0 2rem;
    margin: 7rem 0 1rem 0;
  }

  .portfoliopage ul li span {
    display: block;
    margin-left: 0;
  }

  .portfoliopage ul {
    margin-left: 0;
    padding-left: 0; 
    margin-bottom: 2rem;
  }

  .portfoliopage ul li {
    margin-bottom: 0.5rem;
  }

  .portfolio-home {
    height: 25rem;
  }
}

.skillspage {
  /* background-color: var(--pastel-orange); */
  background-image: url(/static/media/17545-04.784abce5.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skillspage p {
  padding: 0 7rem;
  margin-bottom: 3rem;
}

.skillspage span {
  font-weight: 500;
}

.skillspage ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.skillspage ul li {
  margin-bottom: 0.3rem;
}

.skillspage .designer {
  font-family: 'Oswald', sans-serif;
  display: inline-block;
  background-color: #c3a484;
  padding: 10px 15px;
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
}

.skillspage .webdesigner {
  font-family: 'Oswald', sans-serif;
  display: inline-block;
  background-color: var(--grey-dark);
  color: white !important;
  padding: 10px 15px;
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
}

.skillspage h3 {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 900;
}

.col-lg-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 3rem;
}

.pieChart {
  width: 400px;
  padding: 0 3rem;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.skillspage h1::selection,
.skillspage p::selection,
.skillspage h3::selection,
.skillspage li::selection,
.webdesigner::selection,
.designer::selection,
.skillspage span::selection {
  color: white;
  background-color: var(--grey-dark);
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .skillspage {
    background-image: none;
    background-color: var(--pastel-orange);
    display: block;
    overflow: visible;
    overflow: initial;
    overflow-x: hidden;
    padding-bottom: 2rem;
    align-items: flex-start;
  }

  .skillspage .line {
    margin: 2rem auto;
  }

  .skillspage p {
    padding: 0 2rem;
  }

  .skillspage h1 {
    padding: 0 2rem;
    margin: 7rem 0 1.5rem 0;
  }

  .pieChart {
    width: 300px;
    margin: 1rem 0;
    padding-bottom: 2.5rem;
  }
}

@media only screen and (min-width: 1500px) {
  .skillspage p {
    padding: 0 15rem;
  }
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ac7574;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ac7574;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ac7574;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ac7574;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ac7574;
}

.contactpage {
  /* background-color: var(--pastel-red); */
  background-image: url(/static/media/17545-05.bb448940.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactpage form {
  width: 40vw;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
}

.contactpage form input {
  margin-bottom: 1rem;
  border: none;
  padding: 0.35rem 0.75rem;
}

.contactpage form input:focus,
.contactpage form input:active,
.contactpage form textarea:focus,
.contactpage form textarea:active {
  outline: 2px solid #ac7574;
}

.contactpage form textarea {
  margin-bottom: 1rem;
  border: none;
  padding: 0.35rem 0.75rem;
}

/* SUBMIT BUTTON */

.btn-container {
  display: flex;
  flex-direction: column;
}

.contactpage button {
  color: #ac7574;
  align-self: flex-end;
}

.contactpage button:hover {
  background-color: #ac7574;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.contactpage h1::selection,
.contactpage p::selection,
.contactpage form input::selection,
.contactpage button::selection,
.contactpage form textarea::selection {
  color: white;
  background-color: var(--grey-dark); 
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .contactpage {
    background-image: none;
    background-color: var(--pastel-red);
    overflow: visible;
    overflow: initial;
    padding-bottom: 2rem;
    overflow-x: hidden;
    justify-content: flex-start;
  }

  .contactpage p {
    padding: 0 2rem; 
  }

  .contactpage h1 {
    padding: 0 2rem;
    margin: 7rem 0 1rem 0;
  }

  .contactpage form {
    width: 85vw;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

