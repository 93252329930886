@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;500;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
  --grey-light: #c0c0c0;
  --grey-dark: #262626;
}

* {
  box-sizing: border-box;
  margin: 0;
}

/* NAVBAR STYLING */

/* NAVBAR WEBSITE (VERTICAL) */

.nav-web {
  transform-origin: left top;
  transform: rotate(-90deg) translateX(-100%);
  width: 100vh;
  height: 15vh;
  background-color: white;
  position: absolute;
  z-index: 10;
}

.nav-web ul {
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.nav-web ul li {
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Oswald", sans-serif;
}

.nav-web ul li a:hover {
  color: var(--grey-dark);
  text-decoration: none;
}

.nav-web ul li a {
  color: var(--grey-light);
}

.nav-web .logo {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
  display: flex;
  flex: 0;
  flex-basis: 50px;
  content: " ";
  transform: rotate(90deg);
}

.nav-web ul li a.active {
  color: var(--grey-dark);
}

/* NAVBAR MOBILE (HORIZONTAL) */

.nav-mobile{
  z-index: 100;
  display: none;
}

.navbar-nav li a {
  text-transform: uppercase;
  color: #262626;
  font-family: Oswald;
  font-size: 1.5rem;
}

.navbar-brand a {
  text-transform: uppercase;
  color: #262626;
  font-size: 2.5rem;
  font-weight: 800;
}

.navbar-brand a:hover {
  color: #262626;
}

.navbar{
  display: none !important;
}

.bg-light {
  background-color: white !important;
}

.nav-mobile ul li a.active {
  text-decoration: underline;
}

/* RESPONSIVE SIZES */

@media screen and (max-width: 992px) {
  .nav-web {
    display: none;
    margin-right: 0;
  }

  .navbar{
    display: block !important;
    margin-right: 0;
  }
}
