.aboutpage {
  background-image: url('../Images/17545-02.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutpage .row {
  min-height: 100vh;
}

.photo {
  background-image: url('../Images/profile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 35rem;
}

.about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutpage h2 {
  margin: 1rem 0 1.5rem 0;
}

.aboutpage p {
  padding: 0 5rem;
  margin-bottom: 1rem;
}

.about-text span {
  font-weight: 500;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.aboutpage h1::selection,
.aboutpage p::selection,
.aboutpage h2::selection,
.about-text span::selection {
  color: white;
  background-color: var(--grey-dark);
}

/* ICONS STYLING */

.icons {
  display: flex;
  flex-direction: row;
}

.icons .icon-paragraph {
  padding: 0 1rem;
  font-size: 1.3rem;
  position: relative;
  display: inline-block;
  color: #86a58e;
}

.icons .icon-paragraph:hover {
  transform: rotate(10deg);
  transition: transform 0.5s ease-in-out;
}

#iconTip,
#swimTip,
#sportTip,
#foodTip {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .aboutpage {
    background-image: none;
    background-color: var(--pastel-green);
    padding-bottom: 3rem;
    overflow-x: hidden;
  }

  .aboutpage p {
    padding: 0 2rem;
  }

  .aboutpage h1 {
    padding: 0 2rem;
  }

  .photo {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
