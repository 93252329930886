@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;500;700&family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* PRIMARY COLORS */

:root {
  --grey-light: #c0c0c0;
  --grey-dark: #262626;
  
  /* BACKGROUNDS */
  --pastel-blue: #a5c8e4;
  --pastel-green: #c0eccc;
  --pastel-yellow: #f9f0c1;
  --pastel-orange: #f4cda6;
  --pastel-red: #f6a8a6;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.navbar-div {
  height: 0;
}

.fullpage {
  overflow: hidden;
  margin-left: 15vh;
  height: 100vh;
}

.fullpage h1 {
  font-family: 'Oswald', sans-serif;
  color: var(--grey-dark);
  text-transform: uppercase;
  font-size: 4rem;
  margin: 1.5rem 0;
}

.fullpage h2 {
  font-family: 'Oswald', sans-serif;
  color: var(--grey-dark);
  text-transform: uppercase;
  font-size: 1.5rem;
}

.fullpage p {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: var(--grey-dark);
  line-height: 1.5;
}

/* BUTTON STYLING */

.fullpage .btn {
  width: 10rem;
  background-color: white;
  border: none;
  border-radius: 0;
  padding: 0.5rem 0.75rem;
}

.fullpage .btn:hover {
  color: white;
  border: none;
  transition: background-color 0.25s ease-in;
}

.fullpage .btn:focus,
.fullpage .btn:active {
  outline: none;
}

/* LINE STYLING */

.line {
  height: 10px;
  width: 100px;
  border-bottom: 3px solid var(--grey-dark);
  margin-bottom: 1.5rem;
}

.line:hover {
  width: 250px;
  transition: 1s ease-in-out;
}

/* MOBILE VERSION */

@media only screen and (max-width: 992px) {
  .fullpage {
    margin-left: 0;
    overflow-y: initial;
    height: 100vh;
  }
}
