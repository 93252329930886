.carousel-item img {
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%; /* Center the image within the element */
}

.center-cropped {
  /* width: 100px; */
  height: 100vh;
  overflow: hidden;
}
.center-cropped img {
  height: 100%;
  min-width: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" fill="%23262626" width="8" height="8" viewBox="0 0 8 8"%3e%3cpath d="M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z"/%3e%3c/svg%3e') !important;
}

.carousel-control-next-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23262626' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.carousel-indicators li{
  background-color: #262626 !important;
}

@media only screen and (max-width: 992px) {
  .center-cropped {
    /* width: 100px; */
    height: 50vh;
    overflow: hidden;
  }
  .center-cropped img {
    height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
}
