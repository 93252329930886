.homepage {
  /* background-color: var(--pastel-blue); */
  background-image: url('../Images/17545.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage h1 {
  font-size: 7rem;
  margin-bottom: 0rem;
}

.homepage h2 {
  margin: 1rem 0;
}

.homepage .graphic {
  font-family: 'Courgette', cursive;
  text-transform: lowercase;
  font-size: 2rem;
}

.homepage .developer {
  font-family: 'Source Code Pro', monospace;
  font-size: 1.75rem;
  text-transform: uppercase;
  letter-spacing: -3px;
}

/* BUTTON HOMEPAGE */

.homepage button {
  color: #84a0b6;
  margin-top: 1.5rem;
}

.homepage button:hover {
  background-color: #84a0b6;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.homepage h1::selection,
.homepage h2::selection,
.homepage button::selection,
.homepage .developer::selection,
.homepage .graphic::selection {
  color: white;
  background-color: var(--grey-dark);
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .homepage{
    background-image: none;
    background-color: var(--pastel-blue);
  }
  
  .homepage h1 {
    padding: 0 2rem;
    font-size: 5.5rem;
    line-height: 1;
  }

  .homepage .graphic,
  .homepage .developer {
    display: block;
    margin: 0.5rem 0;
  }
}
