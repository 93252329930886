::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ac7574;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ac7574;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ac7574;
}

.contactpage {
  /* background-color: var(--pastel-red); */
  background-image: url('../Images/17545-05.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactpage form {
  width: 40vw;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
}

.contactpage form input {
  margin-bottom: 1rem;
  border: none;
  padding: 0.35rem 0.75rem;
}

.contactpage form input:focus,
.contactpage form input:active,
.contactpage form textarea:focus,
.contactpage form textarea:active {
  outline: 2px solid #ac7574;
}

.contactpage form textarea {
  margin-bottom: 1rem;
  border: none;
  padding: 0.35rem 0.75rem;
}

/* SUBMIT BUTTON */

.btn-container {
  display: flex;
  flex-direction: column;
}

.contactpage button {
  color: #ac7574;
  align-self: flex-end;
}

.contactpage button:hover {
  background-color: #ac7574;
}

/* TEXT SELECTION COLOR AND BACKGROUND */

.contactpage h1::selection,
.contactpage p::selection,
.contactpage form input::selection,
.contactpage button::selection,
.contactpage form textarea::selection {
  color: white;
  background-color: var(--grey-dark); 
}

/* RESPONSIVE SIZES */

@media only screen and (max-width: 992px) {
  .contactpage {
    background-image: none;
    background-color: var(--pastel-red);
    overflow: initial;
    padding-bottom: 2rem;
    overflow-x: hidden;
    justify-content: flex-start;
  }

  .contactpage p {
    padding: 0 2rem; 
  }

  .contactpage h1 {
    padding: 0 2rem;
    margin: 7rem 0 1rem 0;
  }

  .contactpage form {
    width: 85vw;
  }
}
